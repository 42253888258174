<template>
  <div class="form-container">
    <div class="title-line">
      联系人信息
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>姓名：</p>
        <el-input :maxlength="30" v-model="form.name" class="input" placeholder="请填写联系人姓名" @change="saveLocalForm"/>
      </div>
      <div class="item">
        <p class="item-title"><span>*</span>联系方式：</p>
        <el-input :maxlength="11" v-model="form.phone" class="input" placeholder="请填写联系电话"  @input="handelNumber"/>
      </div>
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>职位：</p>
        <el-input v-model="form.position" :maxlength="30" class="input" placeholder="请填写联系人职位" @change="saveLocalForm"/>
      </div>
      <div class="item">
        <p class="item-title"><span>*</span>验证码：</p>
        <el-input :maxlength="6" v-model="code" class="input" placeholder="请填写验证码"  @input="handelCode"/>
        <button v-if="showCode" @click="getPhoneCode" class="code">验证码</button>
        <button v-else class="disabled">{{ count }}秒</button>
      </div>
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>邮箱：</p>
        <el-input v-model="form.email" class="input" placeholder="请填写联系邮箱" @change="saveLocalForm" :maxlength="50" />
      </div>
    </div>
    <div class="title-line">
      机构信息
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>机构类型：</p>
        <el-select v-model="form.organizationType" placeholder="请选择" @change="saveLocalForm" class="input no-wrap">
          <el-option
            v-for="(item,index) in typeList"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <p class="item-title"><span>*</span>机构名称：</p>
        <el-input :maxlength="50" v-model="form.organizationName" class="input" placeholder="请填写机构名称" @change="saveLocalForm"/>
      </div>
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>机构地址：</p>
        <el-cascader ref="cascaderAddr" :props="props" style="width: 100%;" :placeholder="'请选择机构所在地区'" @visible-change="visibleChange" @change="visibleChangeNew"
        @expand-change="visibleChange" v-model="form.organizationAddress "></el-cascader>
      </div>
    </div>
    <div class="title-line">
      招引需求
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>主要产业领域：</p>
        <el-input v-model="form.industry" class="input" placeholder="请选择" readonly @click="industryDialog = true"/>
        <el-dialog v-model="industryDialog" title="主要产业领域（多选）" :width="'400px'">
          <div class="window" id="IndustryWindow">
            <div v-for="(item,index) in industryArr" :key="index" class="selection" :class="{'active': item.val,'no-bottom': index === industryArr?.length - 1}" @click="item.val=!item.val">
              <p>{{ item.label }}</p>
            </div>
          </div>
          <el-input placeholder="添加您希望招商引进的其它领域，回车确认" v-model="tempInput" class="window-input" @keyup.enter="addNew('industry')"/>
          <template #footer>
            <div class="dialog-footer">
              <el-button type="primary" @click="confirmDialog('industry')">
                保存
              </el-button>
            </div>
          </template>
        </el-dialog>
      </div>
      <div class="item">
        <p class="item-title"><span>*</span>可提供的支持政策与服务：</p>
        <el-input v-model="form.policy" class="input" placeholder="请选择" readonly @click="policyDialog = true"/>
        <el-dialog v-model="policyDialog" title="可提供的支持政策与服务（多选）" :width="'400px'">
          <div class="window" id="PolicyWindow">
            <div v-for="(item,index) in policyArr" :key="index" class="selection" :class="{'active': item.val,'no-bottom': index === policyArr?.length - 1}" @click="item.val=!item.val">
              <p>{{ item.label }}</p>
            </div>
          </div>
          <el-input placeholder="添加您机构能提供的其它支持政策或服务，回车确认" v-model="tempInput" class="window-input" @keyup.enter="addNew('policy')"/>
          <template #footer>
            <div class="dialog-footer">
              <el-button type="primary" @click="confirmDialog('policy')">
                保存
              </el-button>
            </div>
          </template>
        </el-dialog>
      </div>
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>期望引进的项目/企业类型：</p>
        <el-input v-model="form.project" class="input" placeholder="请选择" readonly @click="projectDialog = true"/>
        <el-dialog v-model="projectDialog" title="期望引进的项目/企业类型（多选）" :width="'400px'">
          <div class="window" id="ProjectWindow">
            <div v-for="(item,index) in projectArr" :key="index" class="selection" :class="{'active': item.val,'no-bottom': index === projectArr?.length - 1}" @click="item.val=!item.val">
              <p>{{ item.label }}</p>
            </div>
          </div>
          <el-input placeholder="添加您希望引进的项目或企业的其它具体类型或特点" v-model="tempInput" class="window-input" @keyup.enter="addNew('project')"/>
          <template #footer>
            <div class="dialog-footer">
              <el-button type="primary" @click="confirmDialog('project')">
                保存
              </el-button>
            </div>
          </template>
        </el-dialog>
      </div>
    </div>
    <div class="title-line">
      最新政策与资源
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>可利用资源：</p>
        <el-input v-model="form.resource" class="input" placeholder="请选择" readonly @click="resourceDialog = true"/>
        <el-dialog v-model="resourceDialog" title="可利用资源（多选）" :width="'400px'">
          <div class="window" id="ResourceWindow">
            <div v-for="(item,index) in resourceArr" :key="index" class="selection" :class="{'active': item.val,'no-bottom': index === resourceArr?.length - 1}" @click="item.val=!item.val">
              <p>{{ item.label }}</p>
            </div>
          </div>
          <el-input placeholder="添加您机构目前可提供的其它资源，回车确认" v-model="tempInput" class="window-input" @keyup.enter="addNew('resource')"/>
          <template #footer>
            <div class="dialog-footer">
              <el-button type="primary" @click="confirmDialog('resource')">
                保存
              </el-button>
            </div>
          </template>
        </el-dialog>
      </div>
      <div class="item">
        <p class="item-title"><span>*</span>最新政策摘要：</p>
        <el-input :maxlength="50" v-model="form.summary" class="input" placeholder="请简要概括您机构最近推出的重要政策或优惠" @change="saveLocalForm"/>
      </div>
    </div>
    <div class="item-line">
      <div class="line">
        <p class="item-title">备注信息（选填）：</p>
        <el-input :maxlength="500" type="textarea" resize="none" :rows="4" v-model="form.introduction" class="input" placeholder="如有其他需要说明或补充的信息，请在此处填写" @input="saveLocalForm" show-word-limit/>
      </div>
    </div>
    <div class="button-line">
      <button @click="submit" v-loading="loading">确认提交</button>
    </div>
  </div>
</template>

<script>
import { getLSHLogin,applyLSHForm } from "@/api/LSH/member";
import { getPhoneCode,login } from "@/api/user";
import { ElMessage, } from 'element-plus';
import { getprovince, getcity, getarea } from "@/api/demand";
import { requestVersion } from "@/utils/consts";

export default{
  data(){
    return{
      code: '',
      showCode: true,
      timer: undefined,
      count: 0,
      loading: false,
      tempInput: '',
      resourceDialog: false,
      resourceArr:[
        {
          label: '办公楼宇/场地',
          val: false,
        },
        {
          label: '实验室与研发设施',
          val: false,
        },
        {
          label: '资金支持与融资渠道',
          val: false,
        },
        {
          label: '人才培训与招聘服务',
          val: false,
        },
        {
          label: '产业链上下游企业资源',
          val: false,
        },
      ],
      projectDialog: false,
      projectArr:[
        {
          label: '创新型初创企业与技术研发团队',
          val: false,
        },
        {
          label: '行业领军企业或高成长性企业',
          val: false,
        },
        {
          label: '拥有核心专利技术的企业',
          val: false,
        },
        {
          label: '具有国际市场竞争力的品牌企业',
          val: false,
        },
        {
          label: '提供产业链关键环节服务的企业',
          val: false,
        },
        {
          label: '有助于完善本地产业链的企业',
          val: false,
        },
        {
          label: '研发外包服务或技术转移机构',
          val: false,
        },
        {
          label: '投资机构或金融科技企业',
          val: false,
        },
        {
          label: '文化创意与传媒产业相关企业',
          val: false,
        },
        {
          label: '具有社会责任感及环保理念的企业',
          val: false,
        },
      ],
      policyDialog: false,
      policyArr:[
        {
          label: '税收优惠',
          val: false,
        },
        {
          label: '租金减免',
          val: false,
        },
        {
          label: '科研资金支持',
          val: false,
        },
        {
          label: '人才引进支持',
          val: false,
        },
        {
          label: '技术转移与研发支持',
          val: false,
        },
        {
          label: '市场推广支持',
          val: false,
        },
      ],
      industryDialog: false,
      industryArr: [
        {
          label: '新一代信息技术产业',
          val: false,
        },
        {
          label: '生物医药与大健康',
          val: false,
        },
        {
          label: '新能源与节能环保技术',
          val: false,
        },
        {
          label: '新材料与化工产业',
          val: false,
        },
        {
          label: '软件与信息服务产业 ',
          val: false,
        },
        {
          label: '新能源与智能网联汽车',
          val: false,
        },
        {
          label: '高端装备与先进制造',
          val: false,
        },
        {
          label: '现代农业与食品产业',
          val: false,
        },
        {
          label: '文化创意与旅游产业',
          val: false,
        },
      ],
      typeList: ['产业园区','政府机关','政策服务机构','孵化器','其它服务机构'],
      form:{
        name: '',
        phone: '',
        position: '',
        email: '',
        organizationType: '',
        organizationName: '',
        organizationAddress: [],
        industry: '',
        policy: '',
        project: '',
        resource: '',
        summary: '',
        introduction: '',
      },
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          setTimeout(() => {
            if (node.level == 0) {
              getprovince().then(response => {
                const cities = []
                for (let item of response.data) {
                  cities.push({
                    value: item.provinceCode,
                    label: item.provinceName,
                    leaf: false
                  })
                }
                resolve(cities);
              });
            }
            if (node.level == 1) {
              getcity(node.value).then(response => {
                const areas = [];
                for (let item of response.data) {
                  areas.push({
                    value: item.cityCode,
                    label: item.cityName,
                    leaf: false
                  })
                }
                resolve(areas);
              });
            } else if (node.level == 2) {
              getarea(node.value).then(response => {
                const areas = []
                for (let item of response.data) {
                  areas.push({
                    value: item.areaCode,
                    label: item.areaName,
                    leaf: true
                  })
                }
                resolve(areas);
              });
            }
          }, 100);
        }
      },
    }
  },
  watch:{
    resourceDialog(val){
      if(!val) {
        this.tempInput = ''
        return
      }
      this.form.resource.split(',').forEach((item)=>{
        if(item){
          let flag = false
          for (let index = 0; index < this.resourceArr.length; index++) {
            if(this.resourceArr[index].label === item){
              this.resourceArr[index].val = true
              flag = true
              break
            }
          }
          if(!flag){
            this.resourceArr.push({
              label: item,
              val: true,
            })
          }
        }
      })
    },
    projectDialog(val){
      if(!val) {
        this.tempInput = ''
        return
      }
      this.form.project.split(',').forEach((item)=>{
        if(item){
          let flag = false
          for (let index = 0; index < this.projectArr.length; index++) {
            if(this.projectArr[index].label === item){
              this.projectArr[index].val = true
              flag = true
              break
            }
          }
          if(!flag){
            this.projectArr.push({
              label: item,
              val: true,
            })
          }
        }
      })
    },
    policyDialog(val){
      if(!val) {
        this.tempInput = ''
        return
      }
      this.form.policy.split(',').forEach((item)=>{
        if(item){
          let flag = false
          for (let index = 0; index < this.policyArr.length; index++) {
            if(this.policyArr[index].label === item){
              this.policyArr[index].val = true
              flag = true
              break
            }
          }
          if(!flag){
            this.policyArr.push({
              label: item,
              val: true,
            })
          }
        }
      })
    },
    industryDialog(val){
      if(!val) {
        this.tempInput = ''
        return
      }
      this.form.industry.split(',').forEach((item)=>{
        if(item){
          let flag = false
          for (let index = 0; index < this.industryArr.length; index++) {
            if(this.industryArr[index].label === item){
              this.industryArr[index].val = true
              flag = true
              break
            }
          }
          if(!flag){
            this.industryArr.push({
              label: item,
              val: true,
            })
          }
        }
      })
    },
  },
  mounted(){
    this.loadLocalForm()
  },
  beforeUnmount(){
    if(this.timer){
      this.showCode = true;
      clearInterval(this.timer)
      this.timer = undefined
    }
  },
  methods:{
    getPhoneCode() {   // 验证码处理
      const TIME_COUNT = 60;
      if (this.form.phone.length === 0) {
        ElMessage.warning('请输入手机号码！')
        return
      }
      getPhoneCode({ phone: this.form.phone }).then(() => {
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.showCode = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.showCode = true;
              clearInterval(this.timer)
              this.timer = undefined
            }
          }, 1000)
        }
        ElMessage.success('发送成功')
      })
    },
    submit(){
      if(this.loading){
        return
      }
      else if(!this.form.name){
        ElMessage.warning('请填写联系人姓名')
        return
      }
      else if(!this.form.phone){
        ElMessage.warning('请填写联系电话')
        return
      }
      else if(!this.form.position){
        ElMessage.warning('请填写联系人职位')
        return
      }
      else if(!this.form.email){
        ElMessage.warning('请填写联系邮箱')
        return
      }
      else if(!this.code){
        ElMessage.warning('请填写验证码')
        return
      }
      else if(!this.form.organizationType){
        ElMessage.warning('请选择机构类型')
        return
      }
      else if(!this.form.organizationName){
        ElMessage.warning('请填写机构名称')
        return
      }
      else if(this.form.organizationAddress.length === 0){
        ElMessage.warning('请选择机构所在地区')
        return
      }
      else if(!this.form.industry){
        ElMessage.warning('请选择主要产业领域')
        return
      }
      else if(!this.form.policy){
        ElMessage.warning('请选择可提供的支持政策与服务')
        return
      }
      else if(!this.form.project){
        ElMessage.warning('请选择期望引进的项目/企业类型')
        return
      }
      else if(!this.form.resource){
        ElMessage.warning('请选择可利用资源')
        return
      }
      else if(!this.form.summary){
        ElMessage.warning('请简要概括您机构最近推出的重要政策或优惠')
        return
      }
      this.loading = true
      // 先登录
      login({
        machineCode: "",
        version: requestVersion,
        canal: 1,
        type: 0,
        code: this.code,
        phone: this.form.phone,
        createSource: 'PC-蓝珊瑚产业/政府入驻',  // 创建来源
      }).then(res=>{
        if (res.data && res.data.memberInfo) {
          localStorage.setItem('LSHUserInfo', JSON.stringify(res.data))
          getLSHLogin(res.data.memberInfo.id).then((res1)=>{  // 登录蓝珊瑚
            if(res1.data.status === 1){
              ElMessage.warning('您已经提交过其它入驻信息')
              return
            }
            let arr = []
            arr.push({
              "inputKey": "phone",
              "inputName": "手机号",
              "inputValue": this.form.phone,
            })
            arr.push({
              "inputKey": "name",
              "inputName": "姓名",
              "inputValue": this.form.name,
            })
            arr.push({
              "inputKey": "position",
              "inputName": "联系人职位",
              "inputValue": this.form.position,
            })
            arr.push({
              "inputKey": "organizationName",
              "inputName": "机构名称",
              "inputValue": this.form.organizationName,
            })
            arr.push({
              "inputKey": "email",
              "inputName": "邮箱",
              "inputValue": this.form.email,
            })
            arr.push({
              "inputKey": "organizationType",
              "inputName": "机构类型",
              "inputValue": this.form.organizationType,
            })
            arr.push({
              "inputKey": "organizationAddress",
              "inputName": "机构地址",
              "inputValue": this.$refs[`cascaderAddr`].getCheckedNodes()[0].pathLabels[0] + this.$refs[`cascaderAddr`].getCheckedNodes()[0].pathLabels[1] + this.$refs[`cascaderAddr`].getCheckedNodes()[0].pathLabels[2],
            })
            arr.push({
              "inputKey": "industry",
              "inputName": "主要产业领域",
              "inputValue": this.form.industry,
            })
            arr.push({
              "inputKey": "policy",
              "inputName": "可提供的支持政策与服务",
              "inputValue": this.form.policy,
            })
            arr.push({
              "inputKey": "desire",
              "inputName": "期望引进的项目/企业类型",
              "inputValue": this.form.project,
            })
            arr.push({
              "inputKey": "resource",
              "inputName": "可利用资源",
              "inputValue": this.form.resource,
            })
            arr.push({
              "inputKey": "summary",
              "inputName": "最新政策摘要",
              "inputValue": this.form.summary,
            })
            arr.push({
              "inputKey": "remark",
              "inputName": "备注信息",
              "inputValue": this.form.introduction,
            })
            applyLSHForm({
              "inputItemList": arr,
              "memberId": res.data.memberInfo.id,
              "source": "PC",
              "type": 3
            }).then(()=>{
              ElMessage.success('提交成功')
              this.clearForm()
              this.clearLocalForm()
            }).finally(()=>{
              this.loading = false
            })
          }).finally(()=>{
            this.loading = false
          })
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    addNew(key){
      let dom = null
      switch (key) {
        case 'industry':
          this.industryArr.push({
            label: this.tempInput,
            val: true,
          })
          dom = document.getElementById('IndustryWindow')
          if(dom) {
            this.$nextTick(()=>{
              dom.scrollTo(0, 1 << 20)
            })
          }
          break;
        case 'policy':
          this.policyArr.push({
            label: this.tempInput,
            val: true,
          })
          dom = document.getElementById('PolicyWindow')
          if(dom) {
            this.$nextTick(()=>{
              dom.scrollTo(0, 1 << 20)
            })
          }
          break;
        case 'project':
          this.projectArr.push({
            label: this.tempInput,
            val: true,
          })
          dom = document.getElementById('ProjectWindow')
          if(dom) {
            this.$nextTick(()=>{
              dom.scrollTo(0, 1 << 20)
            })
          }
          break;
        case 'resource':
          this.resourceArr.push({
            label: this.tempInput,
            val: true,
          })
          dom = document.getElementById('ResourceWindow')
          if(dom) {
            this.$nextTick(()=>{
              dom.scrollTo(0, 1 << 20)
            })
          }
          break;
        default:
          break;
      }
      this.tempInput = ''
    },
    confirmDialog(key){
      switch (key) {
        case 'industry':
          this.form.industry = this.industryArr.filter((item)=>item.val).map((item)=>item.label).join(',')
          this.industryDialog = false
          break;
        case 'policy':
          this.form.policy = this.policyArr.filter((item)=>item.val).map((item)=>item.label).join(',')
          this.policyDialog = false
          break;
        case 'project':
          this.form.project = this.projectArr.filter((item)=>item.val).map((item)=>item.label).join(',')
          this.projectDialog = false
          break;
        case 'resource':
          this.form.resource = this.resourceArr.filter((item)=>item.val).map((item)=>item.label).join(',')
          this.resourceDialog = false
          break;
        default:
          break;
      }
      this.tempInput = ''
      this.saveLocalForm()
    },
    visibleChangeNew(){
      this.saveLocalForm()
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    visibleChange(){
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    clearForm(){
      this.form ={
        name: '',
        phone: '',
        position: '',
        email: '',
        organizationType: '',
        organizationName: '',
        organizationAddress: [],
        industry: '',
        policy: '',
        project: '',
        resource: '',
        summary: '',
        introduction: '',
      }
      this.code = ''
      if(this.timer){
        this.showCode = true;
        clearInterval(this.timer)
        this.timer = undefined
      }
    },
    handelNumber(){  // 数字处理
      let pattern = new RegExp("^[0-9]$")
      this.form.phone = Array.from(this.form.phone).filter(v => pattern.test(v)).join("")
      this.saveLocalForm()
    },
    handelCode(){
      let pattern = new RegExp("^[0-9]$")
      this.code = Array.from(this.code).filter(v => pattern.test(v)).join("")
      this.saveLocalForm()
    },
    saveLocalForm(){
      let form = {}
      if(localStorage.getItem('IncubatorForm')){
        form = JSON.parse(localStorage.getItem('IncubatorForm'))
      }
      form.form2 = this.form
      form.form2.code = this.code
      localStorage.setItem('IncubatorForm',JSON.stringify(form))
    },
    clearLocalForm(){
      let form = JSON.parse(localStorage.getItem('IncubatorForm'))
      delete form.form2
      localStorage.setItem('IncubatorForm',JSON.stringify(form))
    },
    loadLocalForm(){
      let form = localStorage.getItem('IncubatorForm')
      if(!form) return
      form = JSON.parse(form)
      if(!form.form2) return
      this.form = form.form2
      this.code = form.form2.code
    },
  }
}
</script>

<style lang="scss" scoped>
.form-container{
  width: 100%;
  .title-line{
    margin: 9px 0;
    height: 40px;
    border-left: 4px solid #0A38C7;
    background: #F3F3F3;
    box-sizing: border-box;
    padding-left: 41px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 40px;
  }
  .item-line{
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 45px;
    .avatar{
      :deep(.el-upload-list){
        display: none;
      }
      .head{
        width: 38px !important;
        height: 38px !important;
        border-radius: 50%;
      }
      .my-upload{
        height: 41px !important;
      }
      .delete{
        position: absolute;
        width: 16px;
        height: 16px;
        left: 42px;
        top: 0;
      }
      .upload-button{
        border: 1px solid #CBCBCB;
        box-sizing: border-box;
        padding: 9px 10px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        line-height: 14px;
        display: flex;
        align-items: center;
        img{
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
      }
    }
    .line{
      width: 100%;
      margin: 8px 0;
      position: relative;
      .upload{
        padding-top: 10px;
        padding-bottom: 10px;
        .my-upload{
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
        .upload-button{
          border: 1px solid #CBCBCB;
          box-sizing: border-box;
          padding: 9px 10px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #111111;
          line-height: 14px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          img{
            width: 18px;
            height: 18px;
            margin-right: 4px;
          }
        }
        :deep(.el-upload-list){
          width: 100%;
          .el-icon-close-tip{
            display: none;
          }
          .el-upload-list__item{
            transition:none !important;
            -webkit-transition:none !important;
          }
          .el-upload-list__item-name{
            transition:none !important;
            -webkit-transition:none !important;
          }
        }
      }
      .left-word{
        position: absolute;
        left: 0;
        top: 0;
        .p-16{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #0A38C7;
          line-height: 16px;
          margin-top: 8px;
          span{
            color: #ED0900;
          }
        }
      }
      .item-title{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        line-height: 16px;
        margin-bottom: 13px;
        span{
          color: #ED0900;
        }
      }
      .input{
        width: 100%;
      }
    }
    .item{
      width: 400px;
      margin: 9px 0;
      position: relative;
      .code{
        position: absolute;
        right: 5px;
        bottom: 3px;
        height: 34px;
        width: 80px;
        border-radius: 0 !important;
        border: none;
        background-color: transparent;
        color: $--color-theme;
        font-size: 14px;
        cursor: pointer;
      }
      .disabled{
        cursor: not-allowed;
        position: absolute;
        right: 5px;
        bottom: 3px;
        height: 34px;
        width: 80px;
        border-radius: 0 !important;
        border: none;
        background-color: transparent;
        color: $--color-theme;
        font-size: 14px;
        opacity: 0.5;
      }
      .right-top-option{
        display: flex;
        position: absolute;
        right: 0;
        top: 5px;
        align-items: center;
        cursor: pointer;
        img{
          width: 21px;
          height: 13px;
          margin-right: 4px;
        }
        p{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #111111;
          line-height: 14px;
        }
      }
      .has-right{
        :deep(.el-input__inner){
          padding-right: 100px;
          box-sizing: border-box;
        }
      }
      .input-right-word{
        position: absolute;
        bottom: 11px;
        right: 15px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        line-height: 14px;
      }
      .input-right{
        position: absolute;
        bottom: 1px;
        right: 1px;
        :deep(.el-input__inner){
          width: 90px;
          border: none;
          height: 38px;
        }
      }
      .selections{
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        .m-r-70{
          margin-right: 70px;
        }
        .selection{
          display: flex;
          align-items: center;
          cursor: pointer;
          img{
            width: 14px;
            height: 14px;
            margin-right: 8px;
          }
          p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #111111;
            line-height: 16px;
          }
        }
      }
      .item-title{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        line-height: 16px;
        margin-bottom: 13px;
        span{
          color: #ED0900;
        }
      }
      .input{
        width: 100%;
      }
      .no-wrap{
        :deep(.el-select__tags) {
          white-space: nowrap;
          overflow: hidden;
        }
        :deep(.el-cascader__tags){
          flex-wrap: nowrap !important;
          overflow: hidden;
        }
      }
    }
  }
  .button-line{
    padding-top: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    button{
      width: 225px;
      height: 36px;
      background: #0A38C7;
      border-radius: 3px;
      cursor: pointer;
      border: none;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 22px;
    }
  }
}
.dialog-footer{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.window{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 30vh;
  overflow: auto;
  .selection{
    width: 100%;
    margin: 10px 0;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #111111;
    line-height: 20px;
    padding: 10px 0;
    cursor: pointer;
    background: #F3F3F3;
    border-radius: 4px;
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
  }
  .active{
    background: #E0E8FF;
    border: 1px solid #0A38C7;
  }
  .no-bottom{
    margin-bottom: 0 !important;
  }
}
.window-input{
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
</style>