import {post} from "@/utils/request";

// 蓝珊瑚登录
export function getLSHLogin(id) {
  return post('/lsh/login', {'memberId': id,})
}

// 蓝珊瑚申请身份
export function applyLSHForm(param) {
  return post('/lsh/applyFor', param)
}