<template>
  <div class="form-container">
    <div class="title-line">
      个人信息
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>头像：</p>
        <div class="avatar">
          <el-upload
            action="#"
            :accept="'image/*'"
            v-model:file-list="form.avatar.files"
            :http-request="handleUpload0"
            :on-remove="handleRemove0"
            :on-exceed="handleExceed0"
            :before-upload="beforeUploadFile0"
            class="my-upload"
            :limit="1">
            <div class="upload-button" v-if="!form.avatar.result" v-loading="uploading">
              <img src="@/assets/images/incubator/new/upload.png" />
              <p>上传近期头像</p>
            </div>
            <div class="position-r" v-else v-loading="uploading">
              <img :src="form.avatar.result" class="head"/>
              <img src="@/assets/images/incubator/new/delete-avatar.png" class="delete" @click.stop="handleRemove0"/>
            </div>
          </el-upload>
        </div>
      </div>
      <div class="item">
        <p class="item-title"><span>*</span>姓名：</p>
        <el-input v-model="form.name" class="input" placeholder="请填写姓名" @change="saveLocalForm" :maxlength="30" />
      </div>
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>机构：</p>
        <el-input v-model="form.organization" class="input" placeholder="请填写所在机构简称" @change="saveLocalForm" :maxlength="30" />
      </div>
      <div class="item">
        <p class="item-title"><span>*</span>职位：</p>
        <el-input v-model="form.position" class="input" placeholder="请填写职位信息" @change="saveLocalForm" :maxlength="30" />
      </div>
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>电话：</p>
        <el-input :maxlength="11" v-model="form.phone" class="input" placeholder="请输入您的电话"  @input="handelNumber"/>
      </div>
      <div class="item">
        <p class="item-title"><span>*</span>邮箱：</p>
        <el-input v-model="form.email" class="input" placeholder="请填写公司邮箱" @change="saveLocalForm" :maxlength="50" />
      </div>
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>验证码：</p>
        <el-input :maxlength="6" v-model="code" class="input" placeholder="请填写验证码"  @input="handelCode"/>
        <button v-if="showCode" @click="getPhoneCode" class="code">验证码</button>
        <button v-else class="disabled">{{ count }}秒</button>
      </div>
    </div>
    <div class="title-line">
      投资偏好
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>关注领域：</p>
        <MultiSelection :selections="multiIndustry" :placeholder="'请选择您主要关注的投资领域，最多10个'" :max="10" :ref="'industrys'" class="no-wrap" :value="form?.industry?.join(',')" @sendValue="sendIndustry" v-if="!reInit"></MultiSelection>
      </div>
      <div class="item">
        <p class="item-title"><span>*</span>投资阶段偏好：</p>
        <el-select v-model="form.prefer" placeholder="请选择您偏好的投资阶段" @change="saveLocalForm" class="input no-wrap" multiple :multiple-limit="5">
          <el-option
            v-for="(item,index) in turnList"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="item-line">
      <div class="item">
        <p class="item-title"><span>*</span>偏好地区：</p>
        <el-select v-model="form.area" placeholder="请选择您投资偏好的地区" @change="saveLocalForm" class="input no-wrap" multiple>
          <el-option
            v-for="(item,index) in areaList"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="item-line">
      <div class="line">
        <div class="left-word">
          <p class="item-title"><span>*</span>名片上传：</p>
          <p class="p-16">*请上传名片，内容必须与所填信息相同（文件大小不超过5MB）</p>
        </div>
        <div class="upload">
          <el-upload
            action="#"
            :accept="'image/*'"
            v-model:file-list="form.pic.files"
            :http-request="handleUpload1"
            :on-remove="handleRemove1"
            :on-exceed="handleExceed1"
            :before-upload="beforeUploadFile1"
            class="my-upload"
            list-type="picture"
            :limit="1">
            <div class="upload-button">
              <img src="@/assets/images/incubator/new/upload.png" />
              <p>上传名片</p>
            </div>
          </el-upload>
        </div>
      </div>
    </div>
    <div class="item-line">
      <div class="line">
        <p class="item-title">投资案例（选填）：</p>
        <el-input :maxlength="500" type="textarea" resize="none" :rows="4" v-model="form.introduction" class="input" placeholder="如有投资案例，请提供文字描述或链接，展示您的投资经验" @input="saveLocalForm" show-word-limit/>
      </div>
    </div>
    <div class="button-line">
      <button @click="submit" v-loading="loading">确认提交</button>
    </div>
  </div>
</template>

<script>
import MultiSelection from '@/components/MultiSelection/MultiSelection';
import { getSelectFatherSonByType } from "@/api/config";
import { ElMessage, } from 'element-plus';
import { requestVersion,uploadUserPhone } from "@/utils/consts";
import { uploadTypeImage,cosByUploadType, } from "@/api/upload";
import { randomFilename } from "@/utils/time";
import { bucketMap } from "@/utils/consts";
import { getLSHLogin,applyLSHForm } from "@/api/LSH/member";
import { getPhoneCode,login } from "@/api/user";

export default{
  components:{
    MultiSelection,
  },
  data(){
    return{
      code: '',
      showCode: true,
      timer: undefined,
      count: 0,
      reInit: false,
      multiIndustry: [],  // 行业多选
      turnList: [
        '种子轮','天使轮','Pre-A','A轮','A+轮','B轮','B+轮','C轮','C+轮','D轮','E轮及以后','Pre-IPO','并购','战略投资','其他轮次'
      ],
      areaList: [
        '北京市','上海市','深圳市','广州市','重庆市','杭州市','天津市','南京市','武汉市','成都市','西安市','苏州市','长沙市','郑州市','济南市','青岛市','宁波市','无锡市','合肥市','佛山市','广东省','江苏省','山东省','浙江省','四川省','河南省','湖北省','福建省','湖南省','安徽省','河北省','陕西省','江西省','辽宁省','云南省','广西省','内蒙古','贵州省','新疆','黑龙江','吉林省','甘肃省','海南省','宁夏省','青海省','西藏','其它',
      ],
      loading: false,
      uploading: false,
      form:{
        avatar: {
          files: [],
          result: '',
        },
        name: '',
        organization: '',
        position: '',
        phone: '',
        email: '',
        industry: [],
        prefer: [],
        area: [],
        pic: {
          files: [],
          result: '',
        },
        introduction: '',
      },
    }
  },
  created(){
    this.getSelections()
  },
  mounted(){
    this.loadLocalForm()
  },
  beforeUnmount(){
    if(this.timer){
      this.showCode = true;
      clearInterval(this.timer)
      this.timer = undefined
    }
  },
  methods:{
    getPhoneCode() {   // 验证码处理
      const TIME_COUNT = 60;
      if (this.form.phone.length === 0) {
        ElMessage.warning('请输入手机号码！')
        return
      }
      getPhoneCode({ phone: this.form.phone }).then(() => {
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.showCode = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.showCode = true;
              clearInterval(this.timer)
              this.timer = undefined
            }
          }, 1000)
        }
        ElMessage.success('发送成功')
      })
    },
    handelCode(){
      let pattern = new RegExp("^[0-9]$")
      this.code = Array.from(this.code).filter(v => pattern.test(v)).join("")
      this.saveLocalForm()
    },
    handelNumber(){  // 数字处理
      let pattern = new RegExp("^[0-9]$")
      this.form.phone = Array.from(this.form.phone).filter(v => pattern.test(v)).join("")
      this.saveLocalForm()
    },
    submit(){
      if(this.uploading){
        ElMessage.warning('正在上传文件，请稍后')
        return
      }
      else if(this.loading){
        return
      }
      else if(!this.form.avatar.result){
        ElMessage.warning('请上传近期头像')
        return
      }
      else if(!this.form.name){
        ElMessage.warning('请填写姓名')
        return
      }
      else if(!this.form.organization){
        ElMessage.warning('请填写所在机构简称')
        return
      }
      else if(!this.form.position){
        ElMessage.warning('请填写职位信息')
        return
      }
      else if(!this.form.phone){
        ElMessage.warning('请输入您的电话')
        return
      }
      else if(!this.code){
        ElMessage.warning('请填写验证码')
        return
      }
      else if(!this.form.email){
        ElMessage.warning('请填写公司邮箱')
        return
      }
      else if(this.form.industry?.length === 0){
        ElMessage.warning('请选择您主要关注的投资领域，最多10个')
        return
      }
      else if(this.form.prefer?.length === 0){
        ElMessage.warning('请选择您偏好的投资阶段')
        return
      }
      else if(this.form.area?.length === 0){
        ElMessage.warning('请选择您投资偏好的地区')
        return
      }
      else if(!this.form.pic.result){
        ElMessage.warning('请上传名片')
        return
      }
      this.loading = true
      // 先登录
      login({
        machineCode: "",
        version: requestVersion,
        canal: 1,
        type: 0,
        code: this.code,
        phone: this.form.phone,
        createSource: 'PC-蓝珊瑚投资机构入驻',  // 创建来源
      }).then(res=>{
        if (res.data && res.data.memberInfo) {
          localStorage.setItem('LSHUserInfo', JSON.stringify(res.data))
          getLSHLogin(res.data.memberInfo.id).then((res1)=>{  // 登录蓝珊瑚
            if(res1.data.status === 1){
              ElMessage.warning('您已经提交过其它入驻信息')
              return
            }
            let arr = []
            arr.push({
              "inputKey": "phone",
              "inputName": "手机号",
              "inputValue": this.form.phone,
            })
            arr.push({
              "inputKey": "name",
              "inputName": "姓名",
              "inputValue": this.form.name,
            })
            arr.push({
              "inputKey": "position",
              "inputName": "职位",
              "inputValue": this.form.position,
            })
            arr.push({
              "inputKey": "belong",
              "inputName": "所在机构",
              "inputValue": this.form.organization,
            })
            arr.push({
              "inputKey": "avatar",
              "inputName": "头像",
              "inputValue": this.form.avatar.result,
            })
            arr.push({
              "inputKey": "industry",
              "inputName": "关注领域",
              "inputValue": this.form.industry.map((item)=>item[1] || item[0]).join('，'),
            })
            arr.push({
              "inputKey": "email",
              "inputName": "邮箱",
              "inputValue": this.form.email,
            })
            arr.push({
              "inputKey": "stage",
              "inputName": "投资偏好阶段",
              "inputValue": this.form.prefer,
            })
            arr.push({
              "inputKey": "address",
              "inputName": "偏好地区",
              "inputValue": this.form.area,
            })
            arr.push({
              "inputKey": "card",
              "inputName": "名片",
              "inputValue": this.form.pic.result,
            })
            arr.push({
              "inputKey": "case",
              "inputName": "投资案例",
              "inputValue": this.form.introduction,
            })
            applyLSHForm({
              "inputItemList": arr,
              "memberId": res.data.memberInfo.id,
              "source": "PC",
              "type": 2
            }).then(()=>{
              ElMessage.success('提交成功')
              this.clearForm()
              this.clearLocalForm()
            }).finally(()=>{
              this.loading = false
            })
          }).finally(()=>{
            this.loading = false
          })
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    sendIndustry(val){
      this.form.industry = val
      this.saveLocalForm()
    },
    beforeUploadFile1(file){
      // 上传文件
      if(file.size > 5 * 1024 * 1024){
        // 大小判断 5M
        ElMessage.warning('上传图片大小过大，不能超过5M!')
        return false
      }
    },
    handleRemove1(){
      this.form.pic.files = []
      this.form.pic.result = ''
      this.saveLocalForm()
    },
    handleExceed1(){
      ElMessage.warning('最多只能上传1个名片')
    },
    handleUpload1(val){
      // 先登录
      login({
        machineCode: '',
        version: requestVersion,
        canal: 1,
        type: 0,
        code: '006688',
        phone: uploadUserPhone,
      }).then(res=>{
        localStorage.setItem('IncubatorTempLogin',JSON.stringify(res.data))
        this.directUploadToTen(val.file).then(res=>{
          this.form.pic.result = 'https://' + res.Location
          this.form.pic.files = [{
            name: val.file.name,
            url: 'https://' + res.Location,
          },]
          this.saveLocalForm()
        }).finally(()=>{
          localStorage.removeItem('IncubatorTempLogin')
        })
      })
    },
    beforeUploadFile0(file){
      // 上传文件
      if(file.size > 50 * 1024 * 1024){
        // 大小判断 50M
        ElMessage.warning('上传文件大小过大，不能超过50M!')
        return false
      }
    },
    handleRemove0(){
      this.form.avatar.files = []
      this.form.avatar.result = ''
      this.saveLocalForm()
    },
    handleExceed0(){
      ElMessage.warning('最多只能上传1个头像')
    },
    handleUpload0(val){
      // 先登录
      login({
        machineCode: '',
        version: requestVersion,
        canal: 1,
        type: 0,
        code: '006688',
        phone: uploadUserPhone,
      }).then(res=>{
        localStorage.setItem('IncubatorTempLogin',JSON.stringify(res.data))
        this.directUploadToTen(val.file).then(res=>{
          this.form.avatar.result = 'https://' + res.Location
          this.form.avatar.files = [{
            name: val.file.name,
            url: 'https://' + res.Location,
          },]
          this.saveLocalForm()
        }).finally(()=>{
          localStorage.removeItem('IncubatorTempLogin')
        })
      })
    },
    directUploadToTen(file){
      let self = this
      let cos = cosByUploadType(uploadTypeImage)
      let bucket = bucketMap.image
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket,
            Region: "ap-shanghai",
            Key: randomFilename(),
            StorageClass: "STANDARD",
            Body: file,
            onProgress: function (progressData) {
              const percentage = parseInt(progressData.percent * 100);
              if (percentage === 100){
                self.uploading = false;
                ElMessage.success('上传成功!')
              }
              else self.uploading = true
            },
          },
          function (err, data) {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          }
        );
      });
    },
    clearForm(){
      this.form = {
        avatar: {
          files: [],
          result: '',
        },
        name: '',
        organization: '',
        position: '',
        phone: '',
        email: '',
        industry: [],
        prefer: [],
        area: [],
        pic: {
          files: [],
          result: '',
        },
        introduction: '',
      }
      this.code = ''
      if(this.timer){
        this.showCode = true;
        clearInterval(this.timer)
        this.timer = undefined
      }
      this.reInit = true
      setTimeout(()=>{
        this.reInit = false
      },300)
    },
    getSelections(){
      getSelectFatherSonByType(6).then(res=>{
        this.multiIndustry = res.data.map((item)=>{
          item.child = item.child?.map((jtem)=>{
            jtem.id = jtem.val
            return jtem
          })
          item.id = item.val
          return item
        })
      })
    },
    saveLocalForm(){
      let form = {}
      if(localStorage.getItem('IncubatorForm')){
        form = JSON.parse(localStorage.getItem('IncubatorForm'))
      }
      form.form1 = this.form
      form.form1.code = this.code
      localStorage.setItem('IncubatorForm',JSON.stringify(form))
    },
    clearLocalForm(){
      let form = JSON.parse(localStorage.getItem('IncubatorForm'))
      delete form.form1
      localStorage.setItem('IncubatorForm',JSON.stringify(form))
    },
    loadLocalForm(){
      let form = localStorage.getItem('IncubatorForm')
      if(!form) return
      form = JSON.parse(form)
      if(!form.form1) return
      this.form = form.form1
      this.code = form.form1.code
    },
  }
}
</script>


<style lang="scss" scoped>
.form-container{
  width: 100%;
  .title-line{
    margin: 9px 0;
    height: 40px;
    border-left: 4px solid #0A38C7;
    background: #F3F3F3;
    box-sizing: border-box;
    padding-left: 41px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 40px;
  }
  .item-line{
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 45px;
    .avatar{
      :deep(.el-upload-list){
        display: none;
      }
      .head{
        width: 38px !important;
        height: 38px !important;
        border-radius: 50%;
      }
      .my-upload{
        height: 41px !important;
      }
      .delete{
        position: absolute;
        width: 16px;
        height: 16px;
        left: 42px;
        top: 0;
      }
      .upload-button{
        border: 1px solid #CBCBCB;
        box-sizing: border-box;
        padding: 9px 10px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        line-height: 14px;
        display: flex;
        align-items: center;
        img{
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
      }
    }
    .line{
      width: 100%;
      margin: 8px 0;
      position: relative;
      .upload{
        padding-top: 10px;
        padding-bottom: 10px;
        .my-upload{
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
        .upload-button{
          border: 1px solid #CBCBCB;
          box-sizing: border-box;
          padding: 9px 10px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #111111;
          line-height: 14px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          img{
            width: 18px;
            height: 18px;
            margin-right: 4px;
          }
        }
        :deep(.el-upload-list){
          width: 100%;
          .el-icon-close-tip{
            display: none;
          }
          .el-upload-list__item{
            transition:none !important;
            -webkit-transition:none !important;
          }
          .el-upload-list__item-name{
            transition:none !important;
            -webkit-transition:none !important;
          }
        }
      }
      .left-word{
        position: absolute;
        left: 0;
        top: 0;
        .p-16{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #0A38C7;
          line-height: 16px;
          margin-top: 8px;
          span{
            color: #ED0900;
          }
        }
      }
      .item-title{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        line-height: 16px;
        margin-bottom: 13px;
        span{
          color: #ED0900;
        }
      }
      .input{
        width: 100%;
      }
    }
    .item{
      width: 400px;
      margin: 9px 0;
      position: relative;
      .code{
        position: absolute;
        right: 5px;
        bottom: 3px;
        height: 34px;
        width: 80px;
        border-radius: 0 !important;
        border: none;
        background-color: transparent;
        color: $--color-theme;
        font-size: 14px;
        cursor: pointer;
      }
      .disabled{
        cursor: not-allowed;
        position: absolute;
        right: 5px;
        bottom: 3px;
        height: 34px;
        width: 80px;
        border-radius: 0 !important;
        border: none;
        background-color: transparent;
        color: $--color-theme;
        font-size: 14px;
        opacity: 0.5;
      }
      .right-top-option{
        display: flex;
        position: absolute;
        right: 0;
        top: 5px;
        align-items: center;
        cursor: pointer;
        img{
          width: 21px;
          height: 13px;
          margin-right: 4px;
        }
        p{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #111111;
          line-height: 14px;
        }
      }
      .has-right{
        :deep(.el-input__inner){
          padding-right: 100px;
          box-sizing: border-box;
        }
      }
      .input-right-word{
        position: absolute;
        bottom: 11px;
        right: 15px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        line-height: 14px;
      }
      .input-right{
        position: absolute;
        bottom: 1px;
        right: 1px;
        :deep(.el-input__inner){
          width: 90px;
          border: none;
          height: 38px;
        }
      }
      .selections{
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        .m-r-70{
          margin-right: 70px;
        }
        .selection{
          display: flex;
          align-items: center;
          cursor: pointer;
          img{
            width: 14px;
            height: 14px;
            margin-right: 8px;
          }
          p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #111111;
            line-height: 16px;
          }
        }
      }
      .item-title{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        line-height: 16px;
        margin-bottom: 13px;
        span{
          color: #ED0900;
        }
      }
      .input{
        width: 100%;
      }
      .no-wrap{
        :deep(.el-select__tags) {
          white-space: nowrap;
          overflow: hidden;
        }
        :deep(.el-cascader__tags){
          flex-wrap: nowrap !important;
          overflow: hidden;
        }
      }
    }
  }
  .button-line{
    padding-top: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    button{
      width: 225px;
      height: 36px;
      background: #0A38C7;
      border-radius: 3px;
      cursor: pointer;
      border: none;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 22px;
    }
  }
}
</style>